import React from 'react'
import { Typography } from 'antd'

import Layout from '../components/layout'
import Head from '../components/head'

const { Title, Paragraph } = Typography

export default function Cgu () {
  return (
    <Layout>
      <Head
        title="Conditions Générales d'Utilisation"
      />

      <div className="content-container space-around-row">
        <Title level='3'>Conditions Générales d'Utilisation</Title>

        <Paragraph>
          Afin de faciliter leur mise à jour et impression, les Conditions Générales d'Utilisation de la plateforme "RocketChart" sont accessibles via ce lien sécurisé :
        </Paragraph>

        <Paragraph>
          <a href="https://docs.google.com/document/d/1O9oxz6_j7CNEpugBYxpnr-U10idcBqlea-2ZO9L_YM4/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Lire les Conditions Générales d'Utilisation</a>
        </Paragraph>

      </div>
    </Layout>
  )
}
